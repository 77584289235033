<template>
  <el-form
    :label-position="labelPosition"
    :label-width="labelWidthFilter"
    :model="model"
    :rules="rules"
    @submit.native.prevent="doFilter"
    class="filter"
    ref="form"
  >
    <el-row>
      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.name.label"
          :prop="fields.name.name"
        >
          <el-input v-model="model[fields.name.name]" />
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.faculty.label"
          :prop="fields.faculty.name"
        >
          <app-autocomplete-one-input
            :fetchFn="fields.faculty.fetchFn"
            v-model="model[fields.faculty.name]"
          ></app-autocomplete-one-input>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.location.label"
          :prop="fields.location.name"
        >
          <app-autocomplete-one-input
            :fetchFn="fields.location.fetchFn"
            v-model="model[fields.location.name]"
          ></app-autocomplete-one-input>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.headOfOutpatientClinic.label"
          :prop="fields.headOfOutpatientClinic.name"
        >
          <app-autocomplete-one-input
            :fetchFn="fields.headOfOutpatientClinic.fetchFn"
            v-model="model[fields.headOfOutpatientClinic.name]"
          ></app-autocomplete-one-input>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.address.label"
          :prop="fields.address.name"
        >
          <el-input v-model="model[fields.address.name]" />
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.emailAddress.label"
          :prop="fields.emailAddress.name"
        >
          <el-input v-model="model[fields.emailAddress.name]" />
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item
          :label="fields.disabledAsStatus.label"
          :prop="fields.disabledAsStatus.name"
        >
          <el-select
            placeholder
            v-model="model[fields.disabledAsStatus.name]"
          >
            <el-option :value="undefined">--</el-option>
            <el-option
              :label="fields.disabledAsStatus.yesLabel"
              :value="true"
              >{{ fields.disabledAsStatus.yesLabel }}</el-option
            >
            <el-option
              :label="fields.disabledAsStatus.noLabel"
              :value="false"
              >{{ fields.disabledAsStatus.noLabel }}</el-option
            >
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>

    <div class="filter-buttons">
      <el-button
        :disabled="loading"
        @click="doFilter"
        icon="el-icon-fa-search"
        type="primary"
      >
        <app-i18n code="common.search"></app-i18n>
      </el-button>

      <el-button
        :disabled="loading"
        @click="doResetFilter"
        icon="el-icon-fa-undo"
      >
        <app-i18n code="common.reset"></app-i18n>
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { OutpatientClinicModel } from '@/modules/outpatient-clinic/outpatient-clinic-model';

const { fields } = OutpatientClinicModel;

const filterSchema = new FilterSchema([
  fields.id,
  fields.name,
  fields.faculty,
  fields.location,
  fields.headOfOutpatientClinic,
  fields.address,
  fields.emailAddress,
  fields.disabledAsStatus,
]);

export default {
  name: 'app-outpatient-clinic-list-filter',

  data() {
    return {
      rules: filterSchema.rules(),
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthFilter: 'layout/labelWidthFilter',
      loading: 'outpatientClinic/list/loading',
      filter: 'outpatientClinic/list/filter',
    }),

    fields() {
      return fields;
    },
  },

  async mounted() {
    this.model = filterSchema.initialValues(
      this.filter,
      this.$route.query,
    );

    return this.doFilter();
  },

  methods: {
    ...mapActions({
      doReset: 'outpatientClinic/list/doReset',
      doFetch: 'outpatientClinic/list/doFetch',
    }),

    async doResetFilter() {
      this.model = filterSchema.initialValues();
      this.$refs.form.clearValidate();
      return this.doReset();
    },

    async doFilter() {
      try {
        await this.$refs.form.validate();
        this.$refs.form.clearValidate();
      } catch (error) {
        return;
      }

      const filter = filterSchema.cast(this.model);
      return this.doFetch({
        filter,
      });
    },
  },
};
</script>

<style>
</style>
