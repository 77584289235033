<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.outpatientClinic.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.outpatientClinic.list.title"></app-i18n>
      </h1>

      <app-outpatient-clinic-list-toolbar></app-outpatient-clinic-list-toolbar>
      <app-outpatient-clinic-list-filter></app-outpatient-clinic-list-filter>
      <app-outpatient-clinic-list-table></app-outpatient-clinic-list-table>
    </div>
  </div>
</template>

<script>
import OutpatientClinicListFilter from '@/modules/outpatient-clinic/components/outpatient-clinic-list-filter.vue';
import OutpatientClinicListTable from '@/modules/outpatient-clinic/components/outpatient-clinic-list-table.vue';
import OutpatientClinicListToolbar from '@/modules/outpatient-clinic/components/outpatient-clinic-list-toolbar.vue';

export default {
  name: 'app-outpatient-clinic-list-page',

  components: {
    [OutpatientClinicListFilter.name]: OutpatientClinicListFilter,
    [OutpatientClinicListTable.name]: OutpatientClinicListTable,
    [OutpatientClinicListToolbar.name]: OutpatientClinicListToolbar,
  },
};
</script>

<style>
</style>
